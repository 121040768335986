@import '../variables.scss';

#products {
    display: flex;
    flex: 1;
    justify-content: stretch;
    align-items: stretch;
    padding: 0;
    height: 60rem;
    overflow: hidden;
    position: relative;
    .carousel-wrapper {
        display: flex;
        flex: 1;
        .owl-carousel .owl-dots {
            position: absolute;
            right: 1.6rem;
            bottom: 1.6rem;
            display: flex;
            justify-content: center;
            width: unset;
            left: unset;
            .owl-dot {
                background-color: unset;
                &.active {
                    span {
                        background-color: $red-orange-color;
                    }
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                span {
                    height: 1.6rem;
                    width: 1.6rem;
                    border-radius: unset;
                    border: 0.2rem solid $white;
                    background-color: rgba($divider-color, 0.6);
                }
            }
        }
        .owl-stage-outer {
            height: 100%;
        }
    }

    .product-item {
        height: 60vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        &.accessories {
            display: none;
        }

        .product-info-wrapper {
            position: absolute;
            left: 1.6rem;
            top: 1.6rem;
            display: flex;
            align-items: start;
            flex-direction: column;
            max-width: 32rem;
            flex: 1;
            padding: 3.2rem;
            background-color: rgba($white, 0.9);
            font-size: $sm-font-size;
            letter-spacing: 0.1rem;
            gap: 1.6rem;

            .product-info-header {
                display: flex;
                flex: 1;
                color: $bootstrap-header-color;
                font-weight: 500;
                font-size: $l-bigger-font-size;
                text-align: left;
            }

            .product-info-description {
                display: flex;
                color: $bootstrap-header-color;
                font-size: $sm-font-size;
            }
            .button-wrapper {
                padding: 0;
                margin: 0;
                .main-button {
                    padding: 0.8rem 1.2rem 0.8rem 1.6rem;
                    gap: 1rem;
                    &::after {
                        display: none;
                    }
                    .custom-icon.chevron-white {
                        height: 1.2rem;
                        width: 1.2rem;
                        background: url('../../images/icons/chevron_right_white.png') no-repeat center;
                        background-size: contain;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    body {
        main.main-content {
            margin-top: 6.4rem;
            #products {
                .carousel-wrapper {
                    .owl-carousel {
                        height: 100%;
                        .owl-dots {
                            top: 1.6rem;
                            bottom: unset;
                        }
                        .owl-stage-outer {
                            max-height: 60vh;
                            .owl-item {
                                height: 100%;
                                .product-item {
                                    display: flex;
                                    flex: 1;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    align-items: stretch;
                                    height: 100%;
                                    height: 32rem;
                                    .product-info-mobile {
                                        padding: 1.6rem;
                                        background-color: rgba($white, 0.9);
                                        .product-info-header {
                                            font-size: $m-font-size;
                                            text-align: left;
                                            color: $bootstrap-header-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
