// basic colors:

$white: #fff;
$black: #000;
$dark-color: #222;
$gray-color: #7e746e;
$medium-gray-color: #aaa;
$light-gray-color: #eee;
$divider-color: #c6c6c6;
$dark-gray-color: #444;
$red-orange-color: #a12e36;
$lightest-gray-color: #fafafa;
$charcoal-color: #434343;
$review-content-color: #888;
$bootstrap-header-color: #212529;

// header colors:
$header-menu-item-border-color: rgba($gray-color, 0.3);
$header-menu-subitem-border-color: rgba($gray-color, 0.2);

// header colors:
$header-border-color: $gray-color;
$header-menu-item-border-color: rgba($gray-color, 0.3);
$header-menu-subitem-border-color: rgba($gray-color, 0.2);

// footer colors:
$footer-font-color: rgba($white, .5);

// breakpoints:
$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-full: 1600px;

// font-sizes:
$xxs-font-size: 1rem;
$xs-font-size: 1.1rem;
$small-font-size: 1.2rem;
$small-base-font-size: 1.3rem;
$base-font-size: 1.4rem;
$base-m-font-size: 1.5rem;
$sm-font-size: 1.6rem;
$m-font-size: 1.8rem;
$ml-font-size: 2rem;
$l-font-size: 2.2rem;
$l-bigger-font-size: 2.4rem;
$xl-font-size: 2.6rem;
$xxl-font-size: 3.2rem;
$xxxl-font-size: 3.5rem;
$xxxxl-font-size: 4.5rem;


// image background mixin

@mixin bgimage($name, $extension: 'png') {
    &.#{$name} {
        $url: "../images/#{$name}.#{$extension}";
        background: url($url) no-repeat center;
        background-size: cover;
    }
}


// box shadow mixin:
@mixin box-shadow($args...) {
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
    -o-box-shadow: $args;
    box-shadow: $args;
}
